<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && popupParam.waterTrustVolumeId"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-4">
                <c-datepicker
                  :editable="editable && !popupParam.waterTrustVolumeId"
                  :required="true"
                  type='month'
                  label="기준월"
                  name="trustYm"
                  v-model="data.trustYm"
                />
              </div>
              <div class="col-4">
                <c-plant :editable="editable && !popupParam.waterTrustVolumeId" :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-4">
                <c-field
                  :required="true"
                  :editable="editable && !popupParam.waterTrustVolumeId"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성부서 & 작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-4">
                <c-text
                  suffix="kg"
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="폐수 전량위탁 처리량"
                  name="trustVolume"
                  v-model="data.trustVolume">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  suffix="kg"
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="폐수 재활용 판매량"
                  name="reVolume"
                  v-model="data.reVolume">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  suffix="kg"
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="생산량"
                  name="salesVolume"
                  v-model="data.salesVolume">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'water-volume-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          waterTrustVolumeId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      saveUrl: transactionConfig.env.water.trust.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      data: {
        waterTrustVolumeId: '',  // 월별 페수처리량 일련번호
        plantCd: null,  // 사업장코드
        trustYm: '',  // 처리년/월
        trustYear: '',  // 처리월
        trustMonth: '',  // 처리월
        trustUnitCd: null,  // 단위
        trustVolume: null,  // 폐수 전량위탁 처리량
        reVolume: null,  // 폐수 재활용판매량
        salesVolume: null,  // 생산량
        userId: '',  // 작성자
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.water.trust.get.url;
      this.checkUrl = selectConfig.env.water.trust.check.url;
      this.insertUrl = transactionConfig.env.water.trust.insert.url;
      this.updateUrl = transactionConfig.env.water.trust.update.url;
      this.deleteUrl = transactionConfig.env.water.trust.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.waterTrustVolumeId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {waterTrustVolumeId: this.popupParam.waterTrustVolumeId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
        },);
      } else {
        this.data.trustYm = this.$comm.getThisMonth();
        this.data.userId = this.$store.getters.user.userId;
      }
    },
    saveInfo() {
      if (this.popupParam.waterTrustVolumeId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {

        // 중복체크
        if (_result) {
          this.$http.url = this.checkUrl;
          this.$http.param = {
            plantCd: this.data.plantCd,
            trustYear: this.data.trustYm.split('-')[0],
            trustMonth: this.data.trustYm.split('-')[1],
          }
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                type: 'info', // success / info / warning / error
                confirmCallback: () => {
                  this.data.trustYear = this.data.trustYm.split('-')[0];
                  this.data.trustMonth = this.data.trustYm.split('-')[1];
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                '동일한 년월['+this.data.trustYm+']에 데이터가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.waterTrustVolumeId = result.data.waterTrustVolumeId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.waterTrustVolumeId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>